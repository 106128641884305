<template>
  <v-select
    v-bind="$attrs"
    v-model="selected"
    :items="companies"
    item-text="name"
    label="Company"
    return-object
    outlined
  >

  </v-select>
</template>

<script>
import axios from "axios";

export default {
  components: {},
  name: "CompanySelect",
  data() {
    return {
      companies: [],
      selected: null,
    };
  },
  created() {
    this.fetchCompanies();
  },
  methods: {
    fetchCompanies() {
      let url = "/api/admin/company";
      axios.get(url).then((response) => {
        this.companies = response.data.content;
      });
    },
  },
  watch: {
    selected() {
      this.$emit('selected', this.selected)
    },
  }
};
</script>

<style scoped>

</style>
